import React, { useEffect, useState,useRef } from "react";
import axios from "axios";
import { HOST } from "../../../../services/constant";

function ListItemVideo({
  items_video,
  pseudo,
  setNotification,
  setNotification_error,
  setMessage_notification,
  setLoading_upload,
  setDeleteVideo
}) {
  const dragItem = useRef()
  const dragOverItem = useRef();
  const [list, setList] = useState([])
  const [valuePosition, setValuePosition] = useState([])
  const [positionToChange,setPositionToChange] = useState(null)
  const [visibleValidation, setVisibleValidation] = useState(false)
  const [indexChange, setIndexChange] = useState(null)

  

  useEffect(() => {
    if (items_video.length > 0) {
      setList(items_video);
      let values = [...valuePosition]
      for(let i =0; i< items_video.length;){
        values[i] = i
        setValuePosition(values)
        i++
      }
    }
  }, [items_video]);

  useEffect(() => {

    if (list.length > 0) {
      const formData = new FormData();
      const server_url = HOST + "reorder_video.php";
      formData.append("neworder", JSON.stringify(list));
      formData.append("user", pseudo);
      axios.post(server_url, formData, { timeout: 60000 })
      .then((res) => {
          let result = res.data;
          console.log(result)
        
      })
      .catch((err) => console.log(err))
      let values = [...valuePosition]
      for(let i =0; i< list.length;){
        values[i] = i
        setValuePosition(values)
        i++
      }
      
    }
  }, [list]);

  const changeOrder = (e, index_video, direction) => {
    e.preventDefault();
    const item_to_move = list.find((item, index) => index == index_video);
    const item_reamaining = list.filter((item, index) => index !== index_video);

    if (direction === "up") {
      const new_index = index_video - 1;
      const reorder_list = [
        ...item_reamaining.slice(0, new_index),
        item_to_move,
        ...item_reamaining.slice(new_index),
      ];

      setList(reorder_list);
    } else {
      const new_index = index_video + 1;
      const reorder_list = [
        ...item_reamaining.slice(0, new_index),
        item_to_move,
        ...item_reamaining.slice(new_index),
      ];

      setList(reorder_list);
    }
  };
  const deleteVideo = (e, id, file) => {

    e.preventDefault();

    setLoading_upload(true);

    const original_list = [...list];

    const new_data = original_list.filter((el, index) => {
      return index != id;
    });
    let lastvideo = 0;


    if (original_list.length == 1 && new_data.length == 0) {
      lastvideo = 1;
    }
   
    const formData = new FormData();
    const server_url = HOST + "delete_video.php";

    formData.append("video", file);
    formData.append("user", pseudo);
    formData.append("lastvideo", lastvideo);

    axios
      .post(server_url, formData, { timeout: 60000 })
      .then((res) => {
        let result = res.data;
        if (result.status == 1) {
          setNotification(true);
          setMessage_notification(result.message);
        } else {
          setNotification(true);
          setNotification_error(true);
          setMessage_notification(result.message);
        }
        setTimeout(() => {
          setLoading_upload(false);
        }, 1000);
      })
      .catch((err) => console.log(err));

    setList(new_data);
    setDeleteVideo(true)
  };
  const changeLoopNumber = (e, id) => {
    const formData = new FormData();
    const server_url = HOST + "update_repetition.php";
    formData.append("position", id);
    formData.append("repetition", e.target.value);
    formData.append("user", pseudo);
    axios
      .post(server_url, formData, { timeout: 60000 })
      .then((res) => {
        let result = res.data;
        setList(result);
      })
      .catch((err) => console.log(err));
  };

  const changePosition =(e,index_video)=>{

    let values = [...valuePosition]
    values[index_video] = parseInt(e.target.value)
    setValuePosition(values)
    setPositionToChange(index_video)
    setVisibleValidation(true)
    setIndexChange(index_video)
  }

  const reorderPosition = (e,index_video,newIndex)=>{

    const item_to_move = list.find((item, index) => index == index_video);
    const item_reamaining = list.filter((item, index) => index != index_video);
    const maxIndex = parseInt(list.length -1)
    if(parseInt(newIndex) <= maxIndex){

      const reorder_list = [
        ...item_reamaining.slice(0, newIndex),
        item_to_move,
        ...item_reamaining.slice(newIndex),
      ];
      
      setList(reorder_list);
      setVisibleValidation(false)

    }else{
      const message_error = 'La position maximale possible : ' + maxIndex
      setNotification(true);
      setNotification_error(true);
      setMessage_notification(message_error);
    }

  }

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
  };

  const renderList = list.map((element, index) => {
    const lastelement = list.length - 1;
    const position = index +1
   
    if (index == 0) {
      return (
        <div
          className="item d-flex flex-row justify-content-between align-items-center grabbable"
          key={index}
          onDragStart={(e) => dragStart(e, index)}
          onDragEnter={(e) => dragEnter(e, index)}
          onDragEnd={drop}
          draggable
          dragClickables="true"
        >
          <span className="item-name text-capitalize">
            {'Etape ' + position +': '+ element.item_name.substring(0, 6)}
          </span>
          <div className="item-loop">
            <div  className="input-group d-flex flex-row justify-content-between ">
              <span  className="input-group-text" id="basic-addon1">
                <i  className="bi bi-bootstrap-reboot"></i>
              </span>
              <input
                type="number"
                className="form-control"
                min="0"
                value={element.loop}
                onChange={(e) => changeLoopNumber(e, index)}
              />
               <div className="d-flex flex-column justify-content-between">
                    <input
                          type="number"
                          className="form-control change-position" 
                          min="0"
                          max={lastelement}
                          value={valuePosition[index]}
                          onChange={(e) => changePosition(e, index)}
                    />
                    <a
                      href="#"
                      className="btn-validate" style={{ display: (visibleValidation && indexChange == index)? 'block' : 'none'}}
                      onClick={(e) => reorderPosition(e,index,valuePosition[index])}
                    >
                      <i className="bi bi-check2-square"></i>
                    </a>
              </div>
            </div>
          </div>
          <a href="#" className="bt-up isdisabled">
            <i className="bi bi-caret-up-fill"></i>
          </a>
          <a
            href="#"
            className="bt-down"
            onClick={(e) => changeOrder(e, index, "down")}
          >
            <i className="bi bi-caret-down-fill"></i>
          </a>

          <a
            href="#"
            className="btn-trash"
            onClick={(e) => deleteVideo(e, index, element.item_file)}
          >
            <i className="bi bi-trash3-fill"></i>
          </a>
        </div>
      );
    } else if (index == lastelement) {
      return (
        <div
          className="item d-flex flex-row justify-content-between align-items-center grabbable"
          key={index}
          onDragStart={(e) => dragStart(e, index)}
          onDragEnter={(e) => dragEnter(e, index)}
          onDragEnd={drop}
          draggable
        >
          <span className="item-name text-capitalize">
            {'Etape ' + position +': '+element.item_name.substring(0, 6)}
          </span>
          <div className="item-loop">
            <div className="input-group d-flex flex-row justify-content-between ">
              <span className="input-group-text" id="basic-addon1">
                <i className="bi bi-bootstrap-reboot"></i>
              </span>
              <input
                type="number"
                className="form-control"
                min="0"
                value={element.loop}
                onChange={(e) => changeLoopNumber(e, index)}
              />
               <div  className="d-flex flex-column justify-content-between ">
                    <input
                          type="number"
                          className="form-control change-position" 
                          min="0"
                          max={lastelement}
                          value={valuePosition[index]}
                          onChange={(e) => changePosition(e, index)}
                    />
                    <a
                      href="#"
                      className="btn-validate" style={{ display: (visibleValidation && indexChange == index)? 'block' : 'none'}}
                      onClick={(e) => reorderPosition(e,index,valuePosition[index])}
                    >
                      <i className="bi bi-check2-square"></i>
                    </a>
              </div>
            </div>
          </div>
          <a
            href="#"
            className="bt-up"
            onClick={(e) => changeOrder(e, index, "up")}
          >
            <i className="bi bi-caret-up-fill"></i>
          </a>
          <a href="#" className="bt-down isdisabled">
            <i className="bi bi-caret-down-fill"></i>
          </a>
          <a
            href="#"
            className="btn-trash"
            onClick={(e) => deleteVideo(e, index, element.item_file)}
          >
            <i className="bi bi-trash3-fill"></i>
          </a>
        </div>
      );
    } else {
      return (
        <div
          className="item d-flex flex-row justify-content-between border-top align-items-center grabbable"
          key={index}
          onDragStart={(e) => dragStart(e, index)}
          onDragEnter={(e) => dragEnter(e, index)}
          onDragEnd={drop}
          draggable
        >
          <span className="item-name text-capitalize">
            {'Etape ' + position +': '+element.item_name.substring(0, 6)}
          </span>
          <div className="item-loop">
            <div  className="input-group d-flex flex-row justify-content-between ">
              <span  className="input-group-text" id="basic-addon1">
                <i  className="bi bi-bootstrap-reboot"></i>
              </span>
              <input
                type="number"
                className="form-control"
                min="0"
                value={element.loop}
                onChange={(e) => changeLoopNumber(e, index)}
              />
              <div  className="d-flex flex-column justify-content-between ">
                    <input
                          type="number"
                          className="form-control change-position" 
                          min="0"
                          max={lastelement}
                          value={valuePosition[index]}
                          onChange={(e) => changePosition(e, index)}
                    />
                    <a
                      href="#"
                      className="btn-validate" style={{ display: (visibleValidation && indexChange == index)? 'block' : 'none'}}
                      onClick={(e) => reorderPosition(e,index,valuePosition[index])}
                    >
                      <i className="bi bi-check2-square"></i>
                    </a>
              </div>
            </div>
          </div>
          <a
            href="#"
            className="bt-up"
            onClick={(e) => changeOrder(e, index, "up")}
          >
            <i className="bi bi-caret-up-fill"></i>
          </a>
          <a
            href="#"
            className="bt-down"
            onClick={(e) => changeOrder(e, index, "down")}
          >
            <i className="bi bi-caret-down-fill"></i>
          </a>
          <a
            href="#"
            className="btn-trash"
            onClick={(e) => deleteVideo(e, index, element.item_file)}
          >
            <i className="bi bi-trash3-fill"></i>
          </a>
        </div>
      );
    }
  });

  return (
    <>
      <div className="d-flex flex-column justify-content-center">
        {renderList}
      </div>
    </>
  );
}
export default ListItemVideo;
