import React,{useState,useRef,useEffect} from 'react';
import axios from 'axios';
import {HOST} from '../../services/constant'
function ControlVideos(
        {
            play_video,
            setPlay_video,
            setOn_endvideo,
            on_endvideo,
            loop_repeat,
            setLoop_repeat,
            setInfiniteLoop,
            setPosition_video,
            position_video,
            total_video,
            setCurrent_time,
            pseudo,
            setUnmuted,
            unmuted
            
        }
){

    const refIcon = useRef(null)
    const refMuted= useRef(null)
    const clickUnmuted = useRef(null)
    const [switchreptition,setSwitchreptition] =useState(false)
 

  
    const play = (e)=>{

        e.preventDefault()
        const icon = refIcon.current
        const classIcon = 'bi bi-play-fill text-black'

        if(icon.className == classIcon ){

            icon.className ='bi bi-pause-fill text-black'
            setPlay_video(true)
            setUnmuted(true)
        }else{

            icon.className ='bi bi-play-fill text-black'
            setPlay_video(false)
        }
        
    }

    const previousVideo=(e)=>{

        e.preventDefault()
        const muteIcon = refMuted.current

        muteIcon.className  ='bi bi-volume-mute text-black'
        setUnmuted('')
        
        
         let prevData =parseInt(e.target.dataset.position)-1
       
         if(prevData > -1 && prevData != 0){

            setPosition_video(parseInt(position_video)-1)
            setCurrent_time(0)

        }else{
        
            setPosition_video(0)
            const icon = refIcon.current
            icon.className ='bi bi-play-fill text-black'
            setPlay_video(false)
            setCurrent_time(0)
        
        }
     
    }
    const nextVideo=(e)=>{

        e.preventDefault()
        const muteIcon = refMuted.current
        muteIcon.className  ='bi bi-volume-mute text-black'
        setUnmuted('')

        let nextData = parseInt(e.target.dataset.position)+1

        if(nextData < total_video  ){
                            
            setPosition_video(parseInt(position_video) + 1)
            const icon = refIcon.current
            icon.className ='bi bi-pause-fill text-black'
            setPlay_video(true)
            setCurrent_time(0)
    
        }
       
       
    }
    const infinteRepettion= (e)=>{

        setSwitchreptition(e.target.checked)
        setInfiniteLoop(e.target.checked)


    }
    const loopNumber = (e)=>{
        
        e.preventDefault()
        setLoop_repeat(e.target.value)

        const formData = new FormData()
        const server_url =  HOST+'update_repetition.php'
        formData.append('position',position_video)
        formData.append('repetition',e.target.value)
        formData.append('user',pseudo)
        axios.post(server_url,formData,{timeout: 60000})
        .then(res=> {
            let result = res.data
        })
        .catch(err=>console.log(err))
    }

    const unmutedButton = (e) =>{
        e.preventDefault()
        const icon = refMuted.current
        const classIcon = 'bi bi-volume-mute text-black'

        if(icon.className == classIcon){
            icon.className  ='bi bi-volume-up text-black'
            setUnmuted("")
        }else{
            icon.className  ='bi bi-volume-mute text-black'
            setUnmuted("muted")
        }
       
    }



    useEffect(()=>{

        if(on_endvideo){

            const icon = refIcon.current
            icon.className ='bi bi-play-fill text-black'
            setPlay_video(false)
            setOn_endvideo(false)

        }
      
    },[on_endvideo])
    


    return(
        <>
        <div className="player">
            <div className="tab_settings d-flex flex-row align-items-center  justify-content-center p-2">
            <div className="d-flex repetition">
                <div className="input-group d-flex flex-row">
                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-bootstrap-reboot"></i></span>
                    <input type="number" className="form-control" min="0" value={loop_repeat} onChange={loopNumber}/>
                </div>
            </div>
            <div className="d-flex flex-row justify-content-around w-75 ">
                <div className="d-flex prev">
                    <a href="#" onClick={previousVideo} >
                        <i className="bi bi-skip-backward-fill text-black" style={{ fontSize: 30 }} data-position = {position_video }></i>
                    </a>
                </div>
                <div className="d-flex">
                    <a href="#" onClick={play}>
                        <i  ref={refIcon} className="bi bi-play-fill text-black" style={{ fontSize: 30 }}></i>
                    </a>
                </div>
             
                <div className="d-flex next">
                    <a href="#" onClick={nextVideo}>
                     <i className="bi bi-skip-forward-fill text-black" style={{ fontSize: 30 }} data-position = {position_video}></i>
                    </a>
                </div>
                <div className="d-flex">
                    <a href="#" ref={clickUnmuted} onClick={unmutedButton}>
                        <i  ref={refMuted} className="bi bi-volume-up text-black" style={{ fontSize: 30 }}></i>
                    </a>
                </div>
            </div>
            <div className="d-flex flex-row align-items-center loop-infinite">
                <div className="form-check form-switch">
                    <input className="form-check-input text-black" type="checkbox" id="flexSwitchCheckDefault"  onChange={infinteRepettion} checked={switchreptition} />
                </div>
                <label className="form-check-label" ><i className="bi bi-arrow-repeat"></i></label>
            </div>
            </div>
        
        </div>
        </>
        
    )
}
export default ControlVideos;