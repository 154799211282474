import React,{useEffect, useRef,useState,Fragment} from 'react';
import DisplayVideos from '../../../components/VideoPlayer/DisplayVideos'
import ControlVideos from '../../../components/VideoPlayer/ControlVideos';
import {useParams,Navigate,useNavigate, Link} from 'react-router-dom';
import {HOST} from '../../../services/constant'
import axios from 'axios';

function Home(){

    const {pseudo} = useParams()
    const [play_video, setPlay_video]=useState(false)
    const [on_endvideo,setOn_endvideo]=useState(false)
    const [on_autoplay,setOn_autoplay]=useState(false)
    const [loop_repeat , setLoop_repeat] = useState(0)
    const [infiniteLoop , setInfiniteLoop] = useState(false)
    const [go_link, setGo_link] = useState(null)
    const [position_video, setPosition_video]= useState(0)
    const [video_to_play, setVideo_to_play]= useState([])
    const [total_video,setTotal_video]= useState(0)
    const [current_time,setCurrent_time] =useState(0)
    const [unmuted,setUnmuted] = useState(false)
    const navigate = useNavigate()

    const addOrUpdateUrlParam = (key,value)=>{
        
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set(key,value)

      const newRelativePathQuery = window.location.pathname+"?"+ searchParams.toString()

      window.history.pushState(null,'',newRelativePathQuery)
    }



  
 
  
  useEffect(()=>{     

      const queryString= window.location.search
      const urlParams = new URLSearchParams(queryString)
      if(!urlParams.has('etape')){
       
        addOrUpdateUrlParam('etape',parseInt(position_video +1))
      }else{
      
        const etape =urlParams.get('etape')
     
        setPosition_video(parseInt(etape)-1)
        
      }
          
    },[])
   
    useEffect(()=>{
        
      if(position_video > -1 ){
     
        addOrUpdateUrlParam('etape',position_video+1)
      
      }

    },[position_video])

     
  useEffect(()=>{

    const server_url = HOST+'get_list_video.php?user='+pseudo
    axios.get(server_url,{timeout: 60000})
    .then(res=> {
        const result = res.data
        if(result.length == 0){

          setGo_link('/parametre/'+pseudo)
          
        }else{

          setVideo_to_play(result[0])
          setLoop_repeat(result[0].loop)
          setTotal_video(result.length)

        }
    })
    .catch(err=>console.log(err))
    
  },[pseudo])

   useEffect(()=>{

        const server_url = HOST+'get_list_video.php?user='+pseudo
        axios.get(server_url,{timeout: 60000})
        .then(res=> {
            const result = res.data
            
            if(result.length == 0){
              setGo_link('/parametre/'+pseudo)
            }else{
              
              if(result[parseInt(position_video)]){

                setVideo_to_play(result[parseInt(position_video)])
                setLoop_repeat(result[parseInt(position_video)].loop)
                setTotal_video(result.length)
                
                /*if(position_video >0){
                  setPlay_video(false)
                  setUnmuted(true)
                }*/

              }else{

                setVideo_to_play(result[0])
                setLoop_repeat(result[0].loop)
                setTotal_video(result.length)
                setPosition_video(0)

              }

            }
        })
        .catch(err=>console.log(err))
        

    },[position_video])
    
    

    if(go_link != null){

      return <Navigate to={go_link} />

    }else{
      return (
        <div className="App">
          <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12 text-center pt-5">
                    <DisplayVideos 
                      video_to_play ={video_to_play}
                      setOn_endvideo={setOn_endvideo}
                      play_video ={play_video} 
                      loop_repeat ={loop_repeat}
                      infiniteLoop={infiniteLoop}
                      pseudo={pseudo}
                      setOn_autoplay={setOn_autoplay}
                      position_video={position_video}
                      setPosition_video={setPosition_video}
                      total_video = {total_video}
                      current_time ={current_time}
                      setCurrent_time={setCurrent_time}
                      setUnmuted={setUnmuted}
                      unmuted ={unmuted}
                      
                     
                    />
                </div>
                <div className="col-md-12 text-center  d-flex flex-column align-items-center">
                    <ControlVideos 
                        play_video={play_video}
                        setPlay_video={setPlay_video} 
                        setLoop_repeat={setLoop_repeat}
                        setOn_endvideo={setOn_endvideo}
                        setInfiniteLoop={setInfiniteLoop}
                        setOn_autoplay={setOn_autoplay}
                        on_autoplay = {on_autoplay}
                        position_video={position_video}
                        setPosition_video={setPosition_video}
                        total_video = {total_video}
                        loop_repeat ={loop_repeat}
                        on_endvideo={on_endvideo}
                        pseudo={pseudo}
                        setCurrent_time={setCurrent_time}
                        setUnmuted ={setUnmuted}
                        unmuted ={unmuted}
                       
                    />
                </div>
            </div>
         
          </div>
            
        </div>
      );
    }
   
}

export default Home