import React,{useState,useRef,useEffect} from 'react';
import {useParams,Link} from 'react-router-dom';
import './styles.scss'


function DisplayVideos({
    pseudo,
    video_to_play,
    play_video,
    setOn_endvideo,
    loop_repeat,
    infiniteLoop,
    position_video,
    setPosition_video,
    current_time,
    setCurrent_time,
    total_video,
    unmuted,
    setUnmuted,

})
    {
    const refVideo = useRef()
    const refScroll = useRef()
    const link_parametre = '/parametre/'+pseudo
    const [repetition,setRepetition] = useState(loop_repeat)
    const [infinite_repetition,setInfinite_repetition] = useState(null)
    const [videolink,setVideolink] = useState('')
    const [titlevideo,setTitlevideo]=useState('')
    const [totalDuration,setTotalDuration]=useState(0)
    const [mins,setMins] = useState(0)
    const [secs,setSecs] = useState('00')
    const [total_mins,setTotal_mins] = useState(0)
    const [total_secs,setTotal_secs] = useState('00')
    const [loading,setLoading] = useState(false)

    

 
    const onPlayVideo = ()=>{

        //const video = refVideo.current
        //setCurrent_time(0)

    }
    const stopVideo = () =>{
     
        if(infinite_repetition){

            const video = refVideo.current
            setMins(0)
            setSecs('00')
            setCurrent_time(0)
            video.play()
            
        }else{

            if(repetition > 0){

                const video = refVideo.current

                setRepetition(repetition -1)
                setMins(0)
                setSecs('00')
                setCurrent_time(0)
                video.play()
        

            }else{
           
                if(position_video < total_video -1){

                    
                    setLoading(true)
                    setUnmuted(true)
                    setTimeout(() => {
                        
                        setPosition_video(position_video +1)
                        setMins(0)
                        setSecs('00')
                        setCurrent_time(0)
                        setLoading(false)
                     
                    },1500)

                }else{
                    setOn_endvideo(true)
              
                }
               
            }
        }
       
    }

    const loadingVideo = ()=>{
        if(loading){
            return(
                <div className="loading d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )
        }
   }


    const progressVideo =(e)=>{
        
        e.preventDefault()
        setCurrent_time(Math.round(e.target.value))
        const video = refVideo.current
        video.currentTime =Math.round(e.target.value)
    }

    const loadedMetadata = ()=>{

        const video = refVideo.current
        setMins(0)
        setSecs('00')
        setTotal_mins(Math.floor(video.duration / 60));
        if(Math.floor(video.duration % 60) < 10){
            setTotal_secs('0' + String(Math.floor(video.duration % 60)));
        }else{
            setTotal_secs(Math.floor(video.duration % 60));
        }
        setTotalDuration(Math.round(video.duration))
    }

    const renderHtmlVideo =()=>{
        if(position_video !=0){

            return(

                <video width="350" height="500" className="cadre_videos" key={titlevideo}
                ref={refVideo} 
                autoPlay
                playsInline
                onEnded={stopVideo}
                onPlay={onPlayVideo}
                onLoadedMetadata ={loadedMetadata}
                //muted={unmuted}
                >
                    <source src={videolink} type="video/mp4"/>
                </video>
    
           )

        }else{

            return(

                <video width="350" height="500" className="cadre_videos" key={titlevideo}
                playsInline
                ref={refVideo} 
                onEnded={stopVideo}
                onPlay={onPlayVideo}
                onLoadedMetadata ={loadedMetadata}
                >
                    <source src={videolink} type="video/mp4"/>
                </video>
    
           )

        }
       
    }
   
   useEffect(()=>{
    
        if(play_video){
            const video = refVideo.current
            video.play()
        }else{

            const video = refVideo.current

            if(video.currentTime != 0){
              
                video.pause()

            }else if(position_video != 0 && video.currentTime == 0){
                video.play()
            }
        }

        setRepetition(loop_repeat)

        setInfinite_repetition(infiniteLoop)

        setVideolink('/'+video_to_play.internal_link)
        setTitlevideo(video_to_play.item_name)

      
    },[video_to_play,play_video,infiniteLoop,loop_repeat])


    useEffect(() => {
       
            const intervalId = setInterval(() => {

                let video = refVideo.current
             
                if(video.currentTime){
                
                    setMins(Math.floor(video.currentTime / 60));
                    if(Math.floor(video.currentTime % 60) < 10){
                        setSecs('0' + String(Math.floor(video.currentTime % 60)));
                    }else{
                        setSecs(Math.floor(video.currentTime % 60));
                    }
                    setCurrent_time(video.currentTime)
                }
    
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
            
        

    }, [totalDuration]);
   
    useEffect(()=>{
        let video = refVideo.current
        if(unmuted == "muted"){
             video.muted =true
        }else{
             video.muted =false
        }
    },[unmuted])

    var isMoving = false
    
    const handleScroll =(e)=>{
        //e.preventDefault()
        console.log(e)
    
   
       /* switch(e.deltaY){
            case 1:
                console.log('up',e.deltaY)
                break
            case -1:
                console.log('down',e.deltaY)
                break
        }*/
        
       /* setTimeout(function(){
            e.currentTarget.scrollTop
        },1000)
        if(e.currentTarget.scrollTop > 0) {
            setPosition_video(position_video+1)
           // alert('top')
            
        }else if(e.currentTarget.scrollBottom >0){
            setPosition_video(position_video-1)
        }*/
     
    }
    useEffect(()=>{
        if(total_video > 0){

            const element = refScroll.current

            const handleWheel = (e)=>{

                setLoading(true)
                element.removeEventListener("wheel", handleWheel)
        
                setTimeout(()=>{
    
                    if(e.deltaY > 0){
                        console.log('up',e.deltaY,position_video)
                        setPosition_video(position_video+1)
                       
                    }else{
                        console.log('down',e.deltaY,position_video)
                        setPosition_video(position_video-1)
                    }
                    setLoading(false)
                },2500)
               

            }
      
            element.addEventListener("wheel", handleWheel)

            const handleTouchMove = (e)=>{

                setLoading(true)
                element.removeEventListener("touchmove", handleTouchMove)
        
                setTimeout(()=>{
    
                    if(e.touches[0].clientY > 0){
                    
                        setPosition_video(position_video+1)
                       
                    }else{
                    
                        setPosition_video(position_video-1)
                    }
                    setLoading(false)
                },2500)
               

            }
      
            element.addEventListener("touchmove", handleTouchMove)

        }
      
    },[position_video,total_video])


    //window.addEventListener('wheel',handleWheel,{ once: true })
   
    return(
        <>
        <div className="row justify-content-center p-0">
               
                <div className="player contain_video" ref={refScroll} /*style={{height:'500px',overflowX:'scroll'}}*/>
                    {loadingVideo()}
                    <div className="tab_settings d-flex flex-row align-items-center  justify-content-between p-2" style={{position:'fixed',width:'375px',zIndex:1}}>
                        <div className="d-flex flex-row align-items-center ">
                            <Link to={'/'}>
                                 <i className="bi bi-box-arrow-in-left" style={{ fontSize: 30 }}></i>
                            </Link>
                        </div>
                        <div className="w-75 d-flex flex-row align-items-center justify-content-between">
                            <span className="text-white" style={{ width: 50 }}>{mins}:{secs}</span>
                            <input type="range" className="form-range"  onChange={progressVideo} min="0"  max={totalDuration} value={current_time} />
                            <span className="text-white"  style={{ width: 50 }}>{total_mins}:{total_secs}</span>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-end">
                            <Link to={link_parametre}>
                                <i className="bi bi-list-ul text-white" style={{ fontSize: 30 }}></i>
                            </Link>
                        </div>
                    </div>
                    <div className="name_step" style={{position:'fixed',top:'110px',width:'375px'}}> <h1 className="text-capitalize text-white">ETAPE {position_video+1} : {video_to_play.item_name}</h1> </div>
                    {renderHtmlVideo()}
                </div>
        
          
        </div>
        </>
    
    )
}
export default DisplayVideos;