import React, { useEffect, useState, useRef } from "react";
import ListItemVideo from "../Parameter/ListItemVideo/ListItemVideo";
import AddNewVideo from "../Parameter/AddNewVideo/AddNewVideo";
import { HOST } from "../../../services/constant";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function Parameter() {

  const { pseudo } = useParams();

  const refAlert = useRef();

  const link_home = "/play-video/" + pseudo;

  const [items_video, setItems_video] = useState([]);

  const [total_video, setTotalVideo] = useState(0);

  const [newVideo, setNewVideo] = useState(false);

  const [deleteVideo, setDeleteVideo] = useState(false);

  const [notification, setNotification] = useState(false);

  const [notification_error, setNotification_error] = useState(false);

  const [message_notification, setMessage_notification] = useState(null);

  const [loading_upload, setLoading_upload] = useState(false);

  useEffect(() => {
    const server_url = HOST + "get_list_video.php?user=" + pseudo;
    //formData.append('url_video',url_video)
    axios
      .get(server_url, { timeout: 60000 })
      .then((res) => {
        const result = res.data;
        setTotalVideo(result.length);
        setItems_video(result);
        setNewVideo(false);
        setDeleteVideo(false)
      })
      .catch((err) => console.log(err));
  }, [newVideo,deleteVideo]);

  const alertNotification = () => {
    if (notification_error && notification) {
      return (
        <div
          className="alert alert-warning alert-dismissible fade show"
          ref={refAlert}
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
          ></button>
          <strong>
            <i className="bi bi-exclamation-triangle-fill"></i>
          </strong>{" "}
          {message_notification}
        </div>
      );
    }
    if (notification_error == false && notification) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          ref={refAlert}
        >
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
          ></button>
          <strong>
            <i className="bi bi-check-circle-fill"></i>
          </strong>{" "}
          {message_notification}
        </div>
      );
    }
  };

  const loadingVideo = () => {
    if (loading_upload) {
      return (
        <div className="loading d-flex justify-content-center align-items-center">
          <div className="spinner-border text-success" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification(false);
        setNotification_error(false);
        setMessage_notification(null);
      }, 1500);
    }
  }, [notification]);

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center pt-5">
            <div className="row justify-content-center p-0">
              <div className="player">
                {loadingVideo()}
                {alertNotification()}
                <div className="tab_settings d-flex flex-row align-items-center  justify-content-between p-2">
                  <div className="d-flex">
                    <Link to={link_home}>
                      <i
                       className="bi bi-file-earmark-play-fill"
                        style={{ fontSize: 30 }}
                      ></i>
                    </Link>
                  </div>
                  <div className="d-flex">
                    <h1 className="text-white">
                      File d'attente ({total_video})
                    </h1>
                  </div>
                </div>
                <ListItemVideo
                  items_video={items_video}
                  pseudo={pseudo}
                  setNewVideo={setNewVideo}
                  setNotification={setNotification}
                  setNotification_error={setNotification_error}
                  setMessage_notification={setMessage_notification}
                  setLoading_upload={setLoading_upload}
                  setDeleteVideo={setDeleteVideo}
                />
                <AddNewVideo
                  pseudo={pseudo}
                  setNotification={setNotification}
                  setNotification_error={setNotification_error}
                  setMessage_notification={setMessage_notification}
                  setLoading_upload={setLoading_upload}
                  setNewVideo={setNewVideo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Parameter;
