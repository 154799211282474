import React,{useState,useRef,useEffect} from "react"
import {Navigate} from 'react-router-dom'
import {HOST} from '../../../services/constant'
import axios from 'axios'


function Connexion(){
    const pseudoRef = useRef()
    const [inputPseudoData,setInputPseudoData] = useState('')
    const [go_link, setGo_link] = useState(null)
    const [pseudo,setPseudo] = useState('')

    const inputPseudo = (e)=>{
        e.preventDefault()
        setInputPseudoData(e.target.value)
    }

    const sendDataUser =(e)=>{
        e.preventDefault()
        setPseudo(pseudoRef.current.value)
    }

    useEffect(()=>{

        if(pseudo !=''){

            const formData = new FormData()
            const server_url = HOST+'user.php'
            formData.append('pseudo',pseudo)
            axios.post(server_url,formData,{timeout: 60000})
            .then(res=> {
                let result = res.data
                if(result.status == 1){
                    setGo_link('/parametre/'+pseudo)
                }else{
                    setGo_link('/play-video/'+pseudo+'?etape=1')
                }
                
             
            })
            .catch(err=>console.log(err))
            }

    },[pseudo])

        if(go_link != null){

            return <Navigate to={go_link} />

        }else{

            return(
                <>
                   <div className="container">
                    <div className="row justify-content-center pt-5">
                        <div className="player">
                            <div className="p-3 text-center">
                                <form className='connexion' onSubmit={sendDataUser} >
                                    <label htmlFor="pseudo" className="form-label text-white">Accéder au lecteur</label>
                                    <input type="text" className="form-control" id="pseudo" placeholder="Votre pseudo" value={inputPseudoData} onChange={inputPseudo} ref={pseudoRef}/>
                                    <input type="submit" className="btn btn-success mt-2" value="Valider"/>
                                </form>
                            </div>
                        </div>
                    </div>
                   </div>
                </>
            )

        }

        
    
   

}
export default Connexion