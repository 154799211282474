import React,{useRef,useState} from 'react';
import {HOST} from '../../../../services/constant'
import axios from 'axios';

function AddNewVideo({
    setNewVideo,
    pseudo,
    setNotification,
    setNotification_error,
    setMessage_notification,
    setLoading_upload
}){

    const linkRef = useRef()

    const inputfile = useRef()

    const [link_video,setLinkVideo] = useState('')

    const writeLink =(e)=>{

        setLinkVideo(e.target.value)
    }
    const onchangeLink = (e)=>{

        if(e.key == 'Enter' || e.type =='blur'){
            const url_video =linkRef.current.value
            const formData = new FormData()
            const server_url = HOST+'upload.php'
            formData.append('url_video',url_video)
            formData.append('pseudo',pseudo)
            axios.post(server_url,formData,{timeout: 60000})
            .then(res=> {
                let result = res.data
    
                if(result.status ==1){
    
                    setNewVideo(true)
                    setLinkVideo('')
                    setNotification(true)
                    setMessage_notification(result.message)
    
                }else{
    
                    setNotification(true)
                    setNotification_error(true)
                    setMessage_notification(result.message)
    
                }
            })
            .catch(err=>console.log(err))
        }
   
    }

    const addVideo = (e)=>{

        e.preventDefault()
        inputfile.current.click()
        
    }

    const addFile=(e)=>{
        setLoading_upload(true)
        e.preventDefault()
        const formData = new FormData()
        Array.from(e.target.files).forEach((file,i) => {    
            formData.append('files[]',file)
        });
  
        formData.append('pseudo',pseudo)
        const server_url = HOST+'upload-local.php'
        axios.post(server_url,formData,{timeout: 60000})
        .then(res=> {
            let result = res.data
            if(result.status == 1){

                setNewVideo(true)
                e.target.value=""
                setNotification(true)
                setMessage_notification(result.message)
                

            }else{
                e.target.value =""
                setNotification(true)
                setNotification_error(true)
                setMessage_notification(result.message)

            }

            setLoading_upload(false)
        })
        .catch(err=>console.log(err))
    
    }

    return(
        <>
           
                <div className="tab_settings d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex pt-2 notification">
                        <span className="text-white"> <i className="bi bi-info-square"></i> Ajouter une video via url  ou en upload via le bouton <i className="bi bi-upload"></i> </span> 
                    </div>
                    <div className="d-flex p-2 w-100 align-items-center justify-content-around">
                            <input ref ={linkRef} type="text" className="form-control " onKeyDown={onchangeLink} onBlur={onchangeLink} onChange={writeLink} value={link_video} placeholder="https://example.com/video.mp4" />
                            &nbsp;
                            <button className="btn btn-success upload" type="button" onClick ={addVideo}><i className="bi bi-upload"></i></button>
                            <input type="file" accept=".mp4" ref={inputfile} style={{ display: 'none' }} onChange={addFile} id="upload" multiple />
                    </div>
                </div>
        </>
    )
}
export default AddNewVideo