import React,{Fragment} from 'react'
import {Routes,Route} from 'react-router-dom';
import Connexion from './components/Page/Connexion/Connexion'
import Home from './components/Page/Home/Home'
import NotFound from './components/Page/NotFound/NotFound'
import Parameter from './components/Page/Parameter/Parameter';

function App() {

  return (
    <Fragment>
        <Routes forceRefresh>
            <Route exact path="/" element={<Connexion/>} />
            <Route exact path="/play-video/:pseudo" element={<Home/>} />
            <Route exact path="/parametre/:pseudo"   element={<Parameter/>} />
            <Route path='*' element={<NotFound/>}></Route>
        </Routes>
    </Fragment>
  );
}

export default App;
